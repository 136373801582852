import Button from "@material-ui/core/Button/Button";
import FormControl from "@material-ui/core/FormControl/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import Alert from "@material-ui/lab/Alert/Alert";
import MuiLink from "@material-ui/core/Link/Link";
import styled from "@material-ui/styles/styled";
import { Link } from "gatsby";
import React from "react";

export const SubmitButton = styled(Button)(() => {
  return {
    color: "white",
    boxShadow: "none !important",
    borderRadius: 10,
    padding: "10px 20px",
    display: "block",
    margin: "0 auto",
    marginTop: "20px"
  };
});

export const CFormControl = styled(FormControl)({
  marginTop: 10,
  marginBottom: 10
});

export const CTextField = styled(TextField)({
  background: "white",
  borderRadius: "4px 4px 0px 0px",
  margin: "0 2px"
});

export const CAlert = styled(Alert)({
  marginTop: "20px"
});

type CLinkProps = { to: string; children: string; navigatedUntrue?: true };
export const CLink: React.FC<CLinkProps> = ({
  to,
  children,
  navigatedUntrue
}) => {
  return (
    <Link to={to} state={{ navigatedTo: !Boolean(navigatedUntrue) }}>
      <MuiLink>{children}</MuiLink>
    </Link>
  );
};

export const CButton = styled(Button)({
  boxShadow: "none!important",
  color: "white",
  borderRadius: 0
});
