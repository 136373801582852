/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./layout.css";
import "typeface-roboto";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MuiLink from "@material-ui/core/Link";
import { grey } from "@material-ui/core/colors";
import { Link } from "gatsby";

import CookieConsent from "react-cookie-consent";
// import { startAnalytics } from "../firebase";
import { CLink } from "../utils/globalStyles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3256A6",
      light: "#618FF2"
    },
    secondary: {
      main: "#A67E21",
      light: "#F2C661"
    }
  },
  typography: {
    fontFamily: "Roboto",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700
  }
});

const Layout = ({ children }: { children: any }) => {
  return (
    <ThemeProvider theme={theme}>
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div style={{ minHeight: "100vh", backgroundColor: grey[100] }}>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div
              style={{
                margin: `0 auto`,
                maxWidth: 960,
                padding: `30px 1.0875rem 1.45rem`
              }}
            >
              <main>{children}</main>
              <footer style={{ paddingTop: 10 }}>
                © {new Date().getFullYear()}, Built with
                {` `}
                <MuiLink color="inherit" href="https://www.gatsbyjs.org">
                  Gatsby
                </MuiLink>
                ; <Link to="/privacy-policy">Privacy Policy</Link>;{" "}
                <Link to="/cookie-policy">Cookie Policy</Link>
              </footer>
            </div>
          </div>
        )}
      />
      <CookieConsent
        buttonText="Frankly, my dear I don't give a damn. (Accept)"
        declineButtonText="You talking to me? (Decline)"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "rgba(220, 220, 220, 0.85)", color: "black", paddingBottom: 10 }}
        buttonStyle={{
          backgroundColor: theme.palette.secondary.light,
          color: "black",
          fontSize: "13px",
          margin: 5,
          marginBottom: 7,
          padding: 10
        }}
        declineButtonStyle={{ fontSize: "13px", margin: 5, marginBottom: 7, padding: 10 }}
        enableDeclineButton
      >
        This website uses cookies to enhance the user experience.{" "}
        <CLink to="/cookie-policy">Cookie Policy</CLink>
      </CookieConsent>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
