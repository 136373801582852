import "../firebase";

const {
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  linkWithCredential,
  updateProfile,
  updateEmail,
  sendEmailVerification,
  signOut,
  sendPasswordResetEmail,
} =
  typeof window !== "undefined"
    ? require("firebase/auth")
    : {
        getAuth: null,
        signInAnonymously: null,
        onAuthStateChanged: null,
        EmailAuthProvider: null,
        createUserWithEmailAndPassword: null,
        signInWithEmailAndPassword: null,
        linkWithCredential: null,
        updateProfile: null,
        updateEmail: null,
        sendEmailVerification: null,
        signOut: null,
        sendPasswordResetEmail: null
      };

// import {
//   getAuth,
//   signInAnonymously,
//   onAuthStateChanged,
//   EmailAuthProvider,
//   createUserWithEmailAndPassword,
//   signInWithEmailAndPassword,
//   linkWithCredential,
//   updateProfile,
//   updateEmail,
//   sendEmailVerification,
//   signOut,
//   sendPasswordResetEmail
// } from "firebase/auth"

class User {
  private uid: string = "";
  private isAnonymous: boolean = false;
  private displayName: string = "";
  private email: string = "";
  private triggers: { callback: () => void; id: number }[] = [];
  private auth: any;
  private verified: boolean = false;
  private loggedIn: boolean;
  public constructor(auth: any) {
    if (typeof window === "undefined") return;
    this.auth = auth;
    onAuthStateChanged(this.auth, (user: any) => {
      if (!user) {
        this.uid = "";
        this.isAnonymous = false;
        this.displayName = "";
        this.verified = false;
        this.loggedIn = false;
        this.email = "";
        return this.trigger();
      }
      this.loggedIn = true;
      this.uid = user.uid;
      this.isAnonymous = user.isAnonymous;
      this.displayName = user.displayName || "";
      this.email = user.email;
      this.verified = user.emailVerified;
      this.trigger();
    });
  }
  public getLoggedIn(): boolean | undefined {
    return this.loggedIn;
  }
  public getUid(): string {
    return this.uid;
  }
  public getIsAnonymous(): boolean {
    return this.isAnonymous;
  }
  public getDisplayName(): string {
    return this.displayName;
  }
  public getEmail(): string {
    return this.email;
  }
  public getVerified(): boolean {
    return this.verified;
  }
  public addTrigger(callback: () => void): () => void {
    const id = Math.random();
    this.triggers.push({ id, callback });
    return () =>
      (this.triggers = this.triggers.filter(({ id: itemId }) => itemId !== id));
  }
  public setDisplayName(displayName: string) {
    return updateProfile(this.auth.currentUser, { displayName }).then(() => {
      this.displayName = displayName;
      this.trigger();
    });
  }
  public setEmail(email: string) {
    return updateEmail(auth.currentUser, email).then(() => {
      this.email = email;
      this.trigger();
    });
  }
  public resetPassword(email: string) {
    return sendPasswordResetEmail(auth!, email);
  }

  public logout() {
    return signOut(this.auth)
      .then(() => {
        this.uid = "";
        this.isAnonymous = false;
        this.displayName = "";
        this.verified = false;
        this.loggedIn = false;
        this.email = "";
        this.trigger();
      });
  }

  private trigger(): void {
    this.triggers.forEach(trigger => trigger.callback());
  }
}

const auth = typeof window !== "undefined" ? getAuth() : null;
export const user = new User(auth);

export const anonSignIn = () => signInAnonymously(auth);

export const convertToPermanent = {
  withPassword: (email: string, password: string) => {
    const credential = EmailAuthProvider.credential(email, password);
    return linkWithCredential(auth.currentUser, credential);
  }
};

export const signUp = {
  withPassword: (email: string, password: string) => {
    if (user.getUid() && user.getIsAnonymous())
      return convertToPermanent.withPassword(email, password);
    if (user.getUid()) return Promise.reject({ message: "Already signed in." });
    return createUserWithEmailAndPassword(auth, email, password);
  }
};

export const sendVerifyEmail = () => {
  return sendEmailVerification(auth.currentUser);
};

export const signIn = {
  withPassword: (email: string, password: string) => {
    // TODO: merge anonymous account with existing sign in account
    if (user.getEmail()) return Promise.reject({ message: "Already signed in." });
    return signInWithEmailAndPassword(auth, email, password);
  }
};
