import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Link, navigate } from "gatsby";

import clsx from "clsx";
import { alpha, makeStyles, useTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import StarIcon from '@material-ui/icons/Star';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/ViewList";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Hidden from "@material-ui/core/Hidden";
import { user } from "../utils/auth";
import Avatar from "@material-ui/core/Avatar";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  title: {
    flexGrow: 1,
    // display: "none",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block"
    // },
    cursor: "pointer",
    color: "black"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    borderBottom: `${grey[300]} solid 1px`,
    background: grey[100]
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  search: {
    position: "relative",
    border: `${grey[300]} solid 1px`,
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.40),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 1)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  inputRoot: {
    // color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  },
  toolbar: {
    margin: "0 auto",
    maxWidth: 960,
    width: "100%"
  },
  link: {
    marginRight: 15,
    color: "black",
  },
  avatar: {
    marginLeft: theme.spacing(8),
    cursor: "pointer"
  }
}));

type Props = { siteTitle: any };
const Header: React.FC<Props> = () => {
  const classes = useStyles();

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [displayName, setDisplayName] = useState(user.getEmail() ? user.getDisplayName() : "");
  useEffect(() => {
    return user.addTrigger(() => setDisplayName(user.getEmail() ? user.getDisplayName() : ""));
  }, []);

  console.log(displayName);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="sticky"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <div className={classes.toolbar}>
          <Toolbar>
            <Hidden smUp>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>

            <Typography
              variant="h6"
              color="black"
              className={classes.title}
              onClick={() => navigate("/")}
            >
              LENOJENO
            </Typography>

            <Hidden xsDown>
              <Link
                to="/articles/top-10"
                state={{ navigatedTo: true }}
                className={`${classes.link} top-bar-link`}
              >
                <Typography>TOP 10</Typography>
              </Link>
              <Link
                to="/films"
                state={{ navigatedTo: true }}
                className={`${classes.link} top-bar-link`}
              >
                <Typography>FILMS</Typography>
              </Link>
              <Link
                to="/reviews"
                state={{ navigatedTo: true }}
                className={`${classes.link} top-bar-link`}
              >
                <Typography>REVIEWS</Typography>
              </Link>
            </Hidden>

            <div className={`${classes.search} ${classes.desktopOnly}`}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                onChange={e => setQuery(e.target.value)}
                onKeyUp={e => {
                  if (e.keyCode === 13) navigate(`/search?q=${query}`);
                }}
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            <Avatar
              className={classes.avatar}
              onClick={() =>
                navigate("/profile", { state: { navigatedTo: true } })
              }
              style={{
                backgroundColor: theme.palette.secondary.light,
                color: "black"
              }}
            >
              {displayName[0]?.toUpperCase()}
            </Avatar>
          </Toolbar>
        </div>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                onChange={e => setQuery(e.target.value)}
                onKeyUp={e => {
                  if (e.keyCode === 13) {
                    setOpen(false);
                    navigate(`/search?q=${query}`);
                  }
                }}
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                onSubmit={() => {
                  setOpen(false);
                  navigate(`/search?q=${query}`)}
                }
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </ListItem>
          <Link to="/">
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </ListItem>
          </Link>
          <Link to="/articles/top-10/">
            <ListItem button>
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText>Top 10</ListItemText>
            </ListItem>
          </Link>
          <Link to="/films">
            <ListItem button>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText>Films</ListItemText>
            </ListItem>
          </Link>
          <Link to="/reviews">
            <ListItem button>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText>Reviews</ListItemText>
            </ListItem>
          </Link>
        </List>
      </Drawer>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
