// Import the functions you need from the SDKs you need
const { initializeApp } =
  typeof window !== "undefined"
    ? require("firebase/app")
    : { initializeApp: null };
const { getFirestore } =
  typeof window !== "undefined"
    ? require("firebase/firestore")
    : { getFirestore: null };
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCnYoKGlRMf5cJs3pR7E1CpgQtDd74QjC0",
  authDomain: "lenojeno-filmwebsite.firebaseapp.com",
  projectId: "lenojeno-filmwebsite",
  storageBucket: "lenojeno-filmwebsite.appspot.com",
  messagingSenderId: "646601259979",
  appId: "1:646601259979:web:2eb813feb82bc9076f144e"
  // measurementId: "G-S6Q3W8BQ9J"
};

// Initialize Firebase
const app =
  typeof window !== "undefined" ? initializeApp(firebaseConfig) : null;

export const db = typeof window !== "undefined" ? getFirestore(app) : null;
